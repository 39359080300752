<template>
  <div class="info-four">
    <van-cell-group>
      <template #title>
        <div style="text-align: left;margin-bottom: 20px;">其它资料</div>
        <div style="text-align: left;">您是否此账户的最终受益拥有人</div>
      </template>
      <van-cell class="van-cell">
        <van-radio-group class="check-group" v-model="form.benefit_self">
          <van-radio name="是">是</van-radio>
          <van-radio name="否">否</van-radio>
        </van-radio-group>
      </van-cell>
      <div v-if="form.benefit_self === '否'">
        <van-field
          label-width="7em"
          v-model="form.beneficiary"
          label="最终受益人姓名"
          clearable
          placeholder="请输入"
          input-align="right"
          maxlength="100"
        />
        <van-field
          label-width="9em"
          v-model="form.beneficiary_id"
          input-align="right"
          label="最终受益人证件号码"
          maxlength="100"
          clearable
          placeholder="请输入"
        />
        <van-field
          label-width="8em"
          v-model="form.beneficiary_tel"
          label="最终受益人电话"
          input-align="right"
          clearable
          maxlength="100"
          placeholder="请输入，如：+86-13600000000"
          :formatter="formatterOfficeTel"
        />
        <van-field
          label-width="9em"
          v-model="form.beneficiary_addr"
          label="最终受益人通讯地址"
          clearable
          input-align="right"
          maxlength="100"
          placeholder="请输入"
        />
      </div>
    </van-cell-group>

    <van-cell-group>
      <template #title>
        <div style="text-align: left">您是否与蜂投证券有限公司或其联营公司之董事或雇员有任何关系</div>
      </template>
      <van-cell class="van-cell">
        <van-radio-group
          class="check-group" v-model="form.beevest_relationship">
          <van-radio name="是">是</van-radio>
          <van-radio name="否">否</van-radio>
        </van-radio-group>
      </van-cell>
      <div
        v-if="form.beevest_relationship === '是'"
        class="text-lable"
      >姓名/关系</div>
      <van-field
        v-if="form.beevest_relationship === '是'"
        clearable
        type="textarea"
        rows="2"
        maxlength="100"
        autosize
        v-model="form.beevest_relationship_name"
        placeholder="请输入。多个之间用“；”隔开。如：张三/父子；李四/叔侄"
      />
    </van-cell-group>

    <van-cell-group>
      <template #title>
        <div style="text-align: left">您是否香港联合交易所有限公司或香港期货交易所有限公司参与者之雇员或任何根据证券期货条例的注册人士</div>
      </template>
      <van-cell class="van-cell">
        <van-radio-group
          class="check-group" v-model="form.hk_exchange_relationship">
          <van-radio name="是">是</van-radio>
          <van-radio name="否">否</van-radio>
        </van-radio-group>
      </van-cell>
      <div
        v-if="form.hk_exchange_relationship === '是'"
        class="text-lable"
      >名称/职位/中央编号</div>
      <van-field
        v-if="form.hk_exchange_relationship === '是'"
        clearable
        maxlength="100"
        type="textarea"
        rows="2"
        autosize
        v-model="form.hk_exchange_relationship_name"
        placeholder="请输入。多个之间用“；”隔开。如：张三/RO/222222；李四/RO/333333"
      />
    </van-cell-group>

    <van-cell-group>
      <template #title>
        <div style="text-align: left">
          您是否在蜂投证券有限公司已开设由您或您代表他人/团体/公司实益持有/拥有/运作或您拥有直接或间接利益的账户（无论个人/联名/公司/托管）
        </div>
      </template>
      <van-cell class="van-cell">
        <van-radio-group
          class="check-group" v-model="form.beevest_account_already">
          <van-radio name="是">是</van-radio>
          <van-radio name="否">否</van-radio>
        </van-radio-group>
      </van-cell>
      <div v-if="form.beevest_account_already === '是'" class="text-lable">账户姓名/账户号码</div>
      <van-field
        v-if="form.beevest_account_already === '是'"
        clearable
        type="textarea"
        rows="2"
        autosize
        v-model="form.beevest_account_already_name"
        maxlength="100"
        placeholder="请输入。多个之间用“；”隔开。如：张三/222222；李四/333333"
      />
    </van-cell-group>

    <van-cell-group>
      <template #title>
        <div style="text-align: left">
          您是否任何在交易所上市公司的主要股东、高级人员、董事
          <van-icon style="top: 1px" name="info-o" @click="handleTip" />
        </div>
      </template>
      <van-cell class="van-cell">
        <van-radio-group
          class="check-group" v-model="form.exchange_vip">
          <van-radio name="是">是</van-radio>
          <van-radio name="否">否</van-radio>
        </van-radio-group>
      </van-cell>
      <div v-if="form.exchange_vip === '是'" class="text-lable">股票名称/股票代码</div>
      <van-field
        v-if="form.exchange_vip === '是'"
        clearable
        type="textarea"
        rows="2"
        autosize
        maxlength="100"
        v-model="form.exchange_vip_stock"
        placeholder="请输入。多个之间用“；”隔开。如：浦发银行/600000.SH；小米集团-W/01810.HK"
      />
    </van-cell-group>

    <van-cell-group>
      <template #title>
        <div style="text-align: left">您是否政界人士或直隶亲属</div>
      </template>
      <van-cell class="van-cell">
        <van-radio-group class="check-group" v-model="form.politicians">
          <van-radio name="是">是</van-radio>
          <van-radio name="否">否</van-radio>
        </van-radio-group>
      </van-cell>
    </van-cell-group>

    <van-cell-group>
      <template #title>
        <div style="text-align: left">您是否与政界人士有不寻常密切的关系，并且受大众广泛地及公开地得悉</div>
      </template>
      <van-cell class="van-cell">
        <van-radio-group
          class="check-group" v-model="form.politicians_relationship">
          <van-radio name="是">是</van-radio>
          <van-radio name="否">否</van-radio>
        </van-radio-group>
      </van-cell>
    </van-cell-group>

    <van-cell-group>
      <template #title>
        <div style="text-align: left">您是否曾被其他持牌机构拒绝进行业务关系</div>
      </template>
      <van-cell class="van-cell">
        <van-radio-group class="check-group" v-model="form.has_reject">
          <van-radio name="是">是</van-radio>
          <van-radio name="否">否</van-radio>
        </van-radio-group>
      </van-cell>
    </van-cell-group>

    <van-cell-group>
      <template #title>
        <div style="text-align: left">您是否有破产纪录</div>
      </template>
      <van-cell class="van-cell">
        <van-radio-group class="check-group" v-model="form.has_broked">
          <van-radio name="是">是</van-radio>
          <van-radio name="否">否</van-radio>
        </van-radio-group>
      </van-cell>
    </van-cell-group>

    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="next" :disabled="disabledNext">
        下一步(税务身份)
      </van-button>
      <van-button class="btn" @click="before">
        上一步
      </van-button>
    </div>

  </div>
</template>

<script>
import {
  Button, RadioGroup, Radio, Field, CellGroup, Cell, Dialog, Icon,
} from 'vant';
import {
  onMounted, reactive, ref, watch,
} from 'vue';
import { useStore } from 'vuex';
import httpApi from '../../utils/httpApi';

let submitForm;
const alertMsg = '“主要股东”指，就一家公司而言，任何人士其于该公司之任何股东大会有权行使，或可控制行使，10%或以上的投票权。';
export default {
  name: 'Home',
  components: {
    [Button.name]: Button,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Icon.name]: Icon,
  },
  data() {
    return {};
  },
  methods: {
    handleTip() {
      Dialog.alert({
        title: '说明',
        messageAlign: 'left',
        message: alertMsg,
        confirmButtonText: '我知道了',
        confirmButtonColor: 'rgba(0,0,0,0.6)',
      });
    },
    async next() {
      if (this.disabledNext.value) return;
      const { code, msg } = await this.$api.postInfoStepFour(submitForm);
      if (code === 200) {
        this.$router.push({ path: '/info/step_five' });
      } else {
        this.$toast(msg);
      }
    },
    before() {
      this.$router.go(-1);
    },
    formatterOfficeTel(value) {
      return value.replace(/[^0-9+-]+/g, '').substr(0, 20);
    },
  },
  setup() {
    const form = reactive({
      benefit_self: '',
      beneficiary: '',
      beneficiary_id: '',
      beneficiary_tel: '',
      beneficiary_addr: '',
      beevest_relationship: '',
      beevest_relationship_name: '',
      hk_exchange_relationship: '',
      hk_exchange_relationship_name: '',
      beevest_account_already: '',
      beevest_account_already_name: '',
      exchange_vip: '',
      exchange_vip_stock: '',
      politicians: '',
      politicians_relationship: '',
      has_reject: '',
      has_broked: '',
    });
    onMounted(async () => {
      const store = useStore();
      store.commit('setTitleValue', '资料填写');
      store.commit('setStepsValue', ['填写资料(4/5)', '风险认知', '提交资料']);
      store.commit('setStepsActive', 0);
      const result = await httpApi.getInfoStepFour();
      if (!result) return;
      Object.getOwnPropertyNames(form).forEach((item) => {
        if (result.data[item]) {
          form[item] = result.data[item];
        }
      });
    });

    const disabledNext = ref(true);
    watch(form, (val) => {
      submitForm = { ...val };

      if (submitForm.benefit_self !== '否') {
        delete submitForm.beneficiary;
        delete submitForm.beneficiary_id;
        delete submitForm.beneficiary_tel;
        delete submitForm.beneficiary_addr;
      }
      if (submitForm.beevest_relationship !== '是') {
        delete submitForm.beevest_relationship_name;
      }
      if (submitForm.hk_exchange_relationship !== '是') {
        delete submitForm.hk_exchange_relationship_name;
      }
      if (submitForm.beevest_account_already !== '是') {
        delete submitForm.beevest_account_already_name;
      }
      if (submitForm.exchange_vip !== '是') {
        delete submitForm.exchange_vip_stock;
      }
      disabledNext.value = false;
      Object.getOwnPropertyNames(submitForm).forEach((item) => {
        if (!submitForm[item].trim()) {
          disabledNext.value = true;
        }
      });
      httpApi.formLog(submitForm, disabledNext);
    });

    return {
      form,
      disabledNext,
    };
  },
};
</script>

<style lang="less">
  .info-four {
    .text-lable {
      text-align: left;
      font-size: @font-size-2;
      margin-left: @space-2;
      margin-top: @space-3;
    }
    .check-group {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      .van-radio {
        flex-basis: 50%;
        margin: 4px;
      }
    }
    .btn {
      width: 80%;
      margin: @space-0 auto;
      margin-bottom: 0;
    }
  }
</style>
